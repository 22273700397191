import React, { useEffect } from "react";
import styled from "styled-components";
import breakpoints from "../../utils/breakpoints";

const Container = styled.div`
  background-color: var(--brand-blue);
  position: relative;
  padding: 4.5rem var(--grid-padding);

  @media ${breakpoints.tabletSm} {
    padding: 8rem calc(var(--grid-padding) * 1.65);
  }

  @media ${breakpoints.tablet} {
    padding: 12rem calc(var(--grid-padding) * 2.5);
  }

  @media ${breakpoints.desktop} {
    padding: 12rem calc(var(--grid-padding) * 2.5);
  }
`;

const Body = styled.div`
  display: block;
  position: relative;
`;

const Form = styled.div`
  width:100%;
  height:500px;
`;

export default function IntroduceYourself({

}) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);
  });
  return (
    <Container>
      <Body>
        <Form data-tf-widget="ms2IzWSI"></Form>
      </Body>
    </Container>
  );
}
